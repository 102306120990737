<template>
  <div class="promo-view" v-if="folderPromo">
    <default-space>
      <div class="d-md-flex">
        <div class="promo-carousel-container" ref="promo_carousel_container" :style="carouselHeight">
          <div class="promo-image-carousel">
            <promo-image-carousel
                :images="images"
                :height="carouselHeight"
                :width="carouselWidth"
            />
          </div>
        </div>
        <div class="promo-content-container">
          <div class="favorite-circle">
            <i
                :class="{'active icon-heart-2': folderPromo.is_favourite, 'icon-heart': !folderPromo.is_favourite}"
                @click="processFavourite"
            />
          </div>

          <div class="promo-title">
            {{ folderPromo.title }}
          </div>

          <div class="price">
            <template v-if="folderPromo.old_price">
              <span class="price--old">
                € {{ folderPromo.old_price }}
              </span>
              <span class="price--separator"> | </span>
            </template>

            <span v-if="folderPromo.new_price" class="price--new">
              € {{ folderPromo.new_price }}
            </span>
            <span v-else class="price--discount">
              {{ $t('post.discount') }}: {{ folderPromo.discount }} %
            </span>
          </div>

          <div class="promo-description">
            {{ folderPromo.description }}
          </div>
          <div class="promo-tags">
            <hash-tags :tags="tags"/>
          </div>

          <div class="promo-button">
            <div class="folder-link my-3">
              <el-button v-if="isFreeClient" @click="openFolder">
                <span class="font-size-16 font-weight-bold" v-t="'promo.view_the_folder'"/>
                <i class="icon-arrow-3"/>
              </el-button>

              <el-button v-else @click="clickButton">
                <span class="font-size-16 font-weight-bold" v-t="'post.go_webshop_btn'"/>
              </el-button>
            </div>
          </div>

          <div class="d-flex justify-content-start">
            <router-localized-link :to="{name: 'folders.store', params: {storeSlug: String(folderPromo.folderPage.folder.store.translation.route)}}">
              <el-avatar
                  :src="authorAvatar"
                  shape="circle"
                  class="promo-author-avatar"
              />
            </router-localized-link>

            <div class="promo-author">
              <div class="promo-author-top-container">
                <div class="promo-author-name">
                  <router-localized-link
                    v-if="editor?.full_name"
                    v-text="editor?.full_name"
                    :to="{name: 'profile', params: {userId: String(editor?.id)}}"
                  />
                  <router-localized-link v-else :to="{name: 'folders.store', params: {storeSlug: String(folderPromo.folderPage.folder.store.translation.route)}}">
                    {{ folderPromo.folderPage.folder.store.translation.title }}
                  </router-localized-link>
                </div>
                <div class="dot-separator">•</div>
                <div class="promo-published-time">
                  {{ folderPromo.published_text }}
                </div>
              </div>
              <div class="promo-valid-time" :class="{'color-primary': !folderPromo.is_valid}">
                {{ folderPromo.valid_text }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <reactions
          class="px-2"
          :rating="folderPromo.rating_calculated"
          :is-like="folderPromo.is_hit"
          :comments-count="commentsCount"
          @react="$refs.commentForm.focusTextarea()"
          @hit="processHit"
          @share="processShare"
      />

      <div class="promo-footer">
        <div class="comments-wrapper mt-1">
          <comments-header :hidden-total="hiddenComments" :total="commentsCount" @show-more="showMoreComments"/>

          <comments-loading :loading="commentsLoading"/>

          <comments :comments-data="visibleCommentsData" @hit="hitCommentProcess"/>

          <comments-loading :loading="commentsPushing"/>

          <comment-form ref="commentForm" @submit="pushComment"/>
        </div>
      </div>
    </default-space>
  </div>
</template>

<script>
import {slug} from '@/mixins/slug';
import {actions} from '@/mixins/post/actions';
import {comments} from '@/mixins/comments';
import {needAuth} from '@/mixins/auth/needAuth';
import {mapActions, mapGetters} from 'vuex';
import {promo_carousel_container} from '@/mixins/promo/promo_carousel_container';

import HashTags from '@/components/feed/post/HashTags';
import Reactions from '@/components/feed/Reactions';
import CommentsHeader from '@/components/feed/comment/CommentsHeader';
import CommentsLoading from '@/components/feed/comment/CommentsLoading';
import Comments from '@/components/feed/comment/Comments';
import CommentForm from '@/components/feed/comment/CommentForm';
import DefaultSpace from '@/views/DefaultSpace';
import PromoImageCarousel from '@/components/feed/promo/PromoImageCarousel';
import RouterLocalizedLink from '@/components/common/RouterLocalizedLink';
import constants from '@/plugins/consts';

export default {
  name: 'FolderPromoView',
  components: {
    PromoImageCarousel,
    DefaultSpace,
    CommentForm,
    Comments,
    CommentsLoading,
    CommentsHeader,
    Reactions,
    HashTags,
    RouterLocalizedLink
  },
  mixins: [actions, comments, slug, needAuth, promo_carousel_container],
  data() {
    return {
      vuexModule: 'promo',
      imageError: false,
    }
  },
  computed: {
    ...mapGetters('promo', [
      'CURRENT_PROMO',
    ]),
    /**
     * @returns {any}
     */
    editor() {
      return this.folderPromo.editor;
    },
    authorAvatar() {
      return !this.editor ? this.folderPromo.folderPage.folder.store.image_url : this.editor.avatar_url
    },
    /**
     * Data for mixin
     * @returns {{morphable_type: string, entity: Object}}
     */
    entityData() {
      return {
        entity: this.folderPromo,
        morphable_type: 'FolderItem'
      }
    },
    folderPromo() {
      return this.CURRENT_PROMO;
    },
    tags() {
      const result = [];
      result.push({
        text: this.convertToSlug(this.$t('post.promos_filter.folder_promos')),
        link: this.$router.resolve({name: 'promos'}).href,
        entityType: 'type'
      });

      if (_.get(this.folderPromo, 'folderPage.folder.store.id', false)) {
        result.push({
          text: this.convertToSlug(_.get(this.folderPromo, 'folderPage.folder.store.translation.title')),
          link: this.$router.resolve({
            name: 'store.show',
            params: {storeSlug: _.get(this.folderPromo, 'folderPage.folder.store.translation.route')}
          }).href,
          entityType: 'store'
        });
      }

      if (_.get(this.folderPromo, 'brands[0].id', false)) {
        result.push({
          text: this.convertToSlug(_.get(this.folderPromo, 'brands[0].translation.title')),
          link: this.$router.resolve({
            name: 'brand.show',
            params: {brandSlug: _.get(this.folderPromo, 'brands[0].translation.route')}
          }).href,
          entityType: 'brand'
        });
      }

      return result;
    },
    images() {
      return [{src: this.folderPromo.image_url}];
    },
    isFreeClient() {
      return constants.FOLDER_CLIENT_TYPES.FOLDER_CLIENT_TYPE_FREE === this.folderPromo.folderPage.folder.store.folder_client_type;
    }
  },
  mounted() {
    this.commentsCount = this.folderPromo.comments_count;
    this.comments = this.folderPromo.comments;
    this.visibleComments = _.min([3, this.folderPromo.comments.length]);
  },
  methods: {
    ...mapActions('promo', [
      'FETCH_CURRENT_PROMO',
      'RESET_PROMOS',
    ]),
    openFolder() {
      this.routerPush({
        name: 'folder.view',
        params: {
          storeSlug: this.folderPromo.folderPage.folder.store.translation.route,
          folderDate: this.folderPromo.folderPage.folder.valid_from,
        },
        query: {
          id: this.folderPromo.folderPage.folder.id,
          page: this.folderPromo.folderPage.page
        }
      });
      this.$emit('folder-link-clicked');
    },
    clickButton() {
      const eventDataToPush = {
        event: 'outgoing_click',
        event_type: 'click_in_folder',
        store_id: this.folderPromo.folderPage.folder.store.id,
        store_title: this.folderPromo.folderPage.folder.store.translation.title,
        folder_item_id: this.folderPromo.id,
        folder_item_title: this.folderPromo.title,
        clicked_link: this.folderPromo.url,
        clicked_text: 'button',
      };
      this.processEvent(eventDataToPush);
      window.open(this.folderPromo.url, '_blank');
    },
    showMoreComments() {
      this.showMoreCommentsProcess('FolderItem', this.folderPromo.id);
    },
    pushComment(form) {
      this.pushCommentProcess('FolderItem', this.folderPromo.id, form);
    },
    processShare(destination) {
      const link = new URL(
          this.$router.resolve({
            name: 'folder_promos.show',
            params: {id: this.folderPromo.id}
          }).href,
          window.location.origin
      ).href;
      this.shareLink(link, destination);
    },
  }
}
</script>
