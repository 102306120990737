const post = {
    getTitle(post) {
        if (post.title) {
            return post.title;
        } else {
            return post.short_content;
        }
    },
};

export default post;
