<template>
  <el-row class="hero-section" v-if="promo">
    <el-col :span="10" :xs="24">
      <h1 class="hero-section__title" v-html="title" @click="onClickPromo"/>
      <p class="hero-section__description cursor-pointer" v-html="description" @click="onClickPromo"/>

      <button v-if="type === 'OnlinePromo'" class="hero-section__btn--promo" @click="openInNewTab">
        {{ $t('post.look_promo') }}
      </button>

      <button v-else-if="type === 'FolderItem'" class="hero-section__btn--promo" @click="showFullPromo">
        {{ $t('post.review_folder') }}
      </button>

      <template v-else-if="type === 'Post'">
        <copy-code-button
          v-if="promo.discount_code || promo.discount_codes_count"
          btn-class="hero-section__btn--promo"
          :code="promo.discount_code"
          :url="promo.discount_url"
          :id="promo.id"
          :event-data="promo.meta_properties"
        />
        <button v-else class="hero-section__btn--promo" @click="showFullPost">
          {{ $t('post.look_post') }}
        </button>
      </template>


    </el-col>
    <el-col :span="12" :xs="24">
      <div class="border-box h-100 pb-2 pb-md-0 position-relative">
        <div class="hero-section__img-wrapper">
          <img :src="imageUrl" alt="" @click="onClickPromo">
        </div>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import CopyCodeButton from '@/components/feed/buttons/CopyCodeButton.vue';

export default {
  name: 'HeroSection',
  components: {CopyCodeButton},
  computed: {
    ...mapGetters('promo-hero', [
      'PROMOS',
      'PROMOS_PROMISE',
      'PROMOS_TOTAL',
      'PROMOS_IS_COMPLETE',
    ]),
    /**
     * Promo
     * @returns {*}
     */
    promo() {
      return _.get(this.PROMOS, '0.data', undefined)
    },
    /**
     * Morph type
     * @returns {*}
     */
    type() {
      return _.get(this.PROMOS, '0.morphable_type')
    },
    /**
     * Post title
     * @returns {*|string}
     */
    title() {
      const data = this.promo

      if (data.title) {
        return data.title
      } else if (data.webshop) {
        return data.webshop.translation.title
      } else if (data.webshops?.length) {
        return data.webshops[0].translation.title
      }

      return this.$t('landing.hero.title')
    },
    /**
     * Description
     * @returns {*}
     */
    description() {
      return this.promo.content
    },
    /**
     * Image url
     * @returns {*|null}
     */
    imageUrl() {
      const data = this.promo

      if (data.images?.length) {
        return data.images[0].src;
      }
      if (data.webshop) {
        return data.webshop.image_url;
      }
      return null;
    }
  },
  data() {
    return {
      fetchOptions: {
        page: 0,
        per_page: 1,
        promoted_section: 'hero-section',
        order: 'promoted',
      },
    }
  },
  mounted() {
    this.FETCH_PROMOS(this.fetchOptions);
  },
  methods: {
    ...mapActions('promo-hero', [
      'FETCH_PROMOS',
    ]),
    onClickPromo() {
      if (this.type === 'Post') {
        this.showFullPost()
      } else if (this.type === 'OnlinePromo') {
        this.openInNewTab()
      } else if (this.type === 'FolderItem') {
        this.showFullPromo()
      }
    },
    openInNewTab() {
      window.open(this.promo.url, '_blank');
    },
    showFullPromo() {
      this.routerPush({name: 'folder_promos.show', params: {id: String(this.promo.id)}});
    },
    showFullPost() {
      this.routerPush({name: 'posts.show', params: {id: String(this.promo.id)}});
    }
  }
}
</script>
