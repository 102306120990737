<template>
  <default-grid :show-right-container="false">
    <template v-slot:left-container>
      <left-bar show-brand-logo show-store-mailer type="BRAND" :show-filters-list="false"></left-bar>
    </template>
    <template v-slot:main-container>
      <div class="promos-feed">
        <div class="d-flex align-items-center header-container">
          <h1 class="promos-feed-title" v-html="h1Text"></h1>
        </div>

        <tabs
          ref="tabs_component"
          :tabs="tabs"
          :default="currentTab"
          @change="tabChange"
          change-query
        />
        <div>
          <div class="my-4.5">
            <search-promos-list
              :items="PROMOS"
              :current-tab="currentTab"
              :vuex-modules="{'post': 'promo', 'online_promo': 'promo', 'folder_promo': 'promo'}"
            />

            <div v-if="!PROMOS_PROMISE && !PROMOS_IS_COMPLETE" class="w-100 centered my-5 pb-5">
              <el-button type="primary"
                         :class="{'w-75':xsOnly,'w-50':!xsOnly}"
                         @click="loadResults">
                <span v-t="'webshop.load_more'"></span>
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </default-grid>
</template>

<script>
import DefaultGrid from '@/views/DefaultGrid';
import LeftBar from '@/components/left_bar/LeftBar';
import Tabs from '@/components/feed/Tabs';
import {mapActions, mapGetters} from 'vuex';
import BrandRoute from '@/router/guards/BrandRoute';
import SearchPromosList from '@/components/feed/SearchPromosList';
import {store_webshop_brand_product} from '@/mixins/group/store_webshop_brand_product';

export default {
  name: 'BrandView',
  components: {
    SearchPromosList,
    Tabs,
    LeftBar,
    DefaultGrid
  },
  mixins: [store_webshop_brand_product],
  data() {
    return {
      currentTab: _.get(this.$route.query, 'tab', 'default'),
      fetchOptions: {
        type_kind_not: this.$consts.KIND.QUESTION,
        include_filters: ['types'],
        types: [],

        q: '',
        per_page: 15,
      }
    }
  },
  computed: {
    ...mapGetters('brand', [
      'CURRENT_BRAND',
    ]),
    ...mapGetters('auth', [
      'GUEST',
    ]),
    /**
     * Tabs
     * @returns {{}}
     */
    tabs() {
      const tabs = {};
      tabs['default'] = this.$t('brand.tabs.default');
      if (this.CURRENT_BRAND.total_folder_items > 0) {
        tabs['folder-promos'] = this.$t('brand.tabs.folder-promos');
      }
      if (this.CURRENT_BRAND.total_online_promos > 0) {
        tabs['online-promos'] = this.$t('brand.tabs.online-promos');
      }
      tabs['posts'] = this.$t('brand.tabs.posts');

      return tabs;
    },
    /**
     * Title text
     * @returns {*}
     */
    h1Text() {
      const date = new Date();
      const m = date.toLocaleString(this.$i18n.locale, {month: 'long'});
      const y = date.getFullYear();
      return this.$t('brand.brand_page_h1', {brand: this.CURRENT_BRAND.translation.title, month: m, year: y});
    }
  },
  // need this because Guards doesnt work when the same route params changing
  async beforeRouteUpdate(to, from) {
    if (from.params.brandSlug !== to.params.brandSlug) {
      await BrandRoute(to);
    }
  },
  methods: {
    prepareFetchOptions() {
      this.SET_FILTER_ACTIVE('types_filter');

      this.fetchOptions.brand = this.CURRENT_BRAND.id;
      // this.fetchOptions.q = this.CURRENT_BRAND.translation.title;
      this.fetchOptions.types = this.FILTERS_VALUES.types_filter;
      this.fetchOptions.order = 'codes_first';

      switch (this.currentTab) {
        case 'online-promos':
          this.SET_FILTER_INACTIVE('types_filter');
          this.fetchOptions.types = [];
          this.fetchOptions.morphable_types = ['OnlinePromo'];
          break;
        case 'folder-promos':
          this.SET_FILTER_INACTIVE('types_filter');
          this.fetchOptions.types = [];
          this.fetchOptions.morphable_types = ['FolderItem'];
          break;
        case 'posts':
          this.fetchOptions.morphable_types = ['Post'];
          break;
        case 'default':
        default:
          this.fetchOptions.morphable_types = [];
          break;
      }
    }
  }
}
</script>
