<template>
    <span @click="openLink" class="color-primary cursor-pointer span-link" ref="main_span">
         <slot></slot>
    </span>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'span-link',
  props: {
    href: {
      type: String
    },
    target_promo: {
      type: String,
      default: ''
    },
    target: {
      type: String,
      default: '_self'
    },
    rel: {
      type: String,
      default: 'nofollow'
    },
    eventData: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  computed: {
    ...mapGetters('auth', [
      'USER',
    ]),
  },
  methods: {
    openLink() {
      let target = this.href.includes('http') ? '_blank' : '_self';
      if (!_.isEmpty(this.target_promo)) {
        target = this.target_promo;
      }

      if (_.has(this.eventData, 'event')) {
        const eventDataToPush = this.eventData;
        eventDataToPush.user_id = _.get(this.USER, 'id', 0)
        eventDataToPush.clicked_link = this.href;
        eventDataToPush.clicked_text = this.$refs.main_span.innerText;

        this.processEvent(eventDataToPush);

        setTimeout(() => {
          window.open(this.href, target);
        }, 300); // 300ms should generally be enough
      } else {
        window.open(this.href, target);
      }
    }
  }
}
</script>
