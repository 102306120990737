<template>
  <template v-if="!GUEST">
    <div class="header-actions">
      <div class="favorites-wrapper">
        <router-localized-link class="cursor-pointer" :to="{name : 'favourites'}">
          <i class="icon-heart-2"/>
        </router-localized-link>
      </div>
      <notifications-dropdown/>

      <div class="d-flex cursor-pointer avatar-wrapper" @click="$dialogs.toggle('user-menu')">
        <el-avatar
          class="user-avatar"
          :src="USER.avatar_url"
          shape="circle"
        />
        <i class="icon-arrow-down"/>
      </div>
      <div @click="$dialogs.hide('user-menu')" v-if="dialogs['user-menu'].visible" class="user-menu__overlay"/>
      <user-menu/>
    </div>
  </template>
  <dialogs/>
</template>

<script>
import {mapGetters, mapState} from 'vuex';
import Dialogs from '@/components/header/Dialogs.vue';
import RouterLocalizedLink from '@/components/common/RouterLocalizedLink.vue';
import UserMenu from '@/components/header/UserMenu.vue';
import NotificationsDropdown from '@/components/header/dropdowns/NotificationsDropdown.vue';

export default {
  name: 'HeaderActions',
  components: {UserMenu, RouterLocalizedLink, NotificationsDropdown, Dialogs},
  computed: {
    ...mapGetters('auth', [
      'USER',
      'GUEST',
    ]),
    ...mapState('dialogs', ['dialogs']),

    availableCoinsString() {
      const coins = _.get(this, 'USER.account.available', 0);
      if (coins < 1000) {
        return String(coins);
      }
      return _.ceil(coins / 1000, 1) + 'k';
    }
  },
  mounted() {
    setTimeout(() => {
      if (_.get(this.$route, 'hash') === '#signup' && this.GUEST) {
        window.open(this.$router.resolve({name: 'register'}).href, '_self');
      }

      if (_.get(this.$route, 'hash') === '#verification' && this.GUEST) {
        this.$notify.success({
          title: this.$t('notifications.registration_completed.title'),
          message: this.$t('notifications.registration_completed.message'),
          position: this.notificationPosition,
          duration: this.notificationDuration,
        });
      }

      if (_.get(this.$route, 'hash') === '#verified' && this.GUEST) {
        if (cookies.get('registered_email')) {
          console.log('Event "nieuw_lid" for new user "' + cookies.get('registered_email') + '"');
          window.dataLayer?.push({
            'event': 'nieuw_lid',
            'email': cookies.get('registered_email')
          });
          cookies.remove('registered_email');
        }
        // this.$dialogs.show('auth', 'verified');
        this.$notify.success({
          title: this.$t('notifications.verification.success.title'),
          message: this.$t('notifications.verification.success.message'),
          position: this.notificationPosition,
          duration: this.notificationDuration,
        });
      }
    }, 1000);
  },
  watch: {
    '$route.path'() {
      if (this.dialogs['user-menu']?.visible)
        this.$dialogs.toggle('user-menu')
    }
  },
}
</script>
