import dates from '@/plugins/dates';
import post from '@/plugins/post';
import store from '@/store';
//guards
import Auth from '@/router/guards/Auth';
import Guest from '@/router/guards/Guest';
import StoreRoute from '@/router/guards/StoreRoute';
import FolderRoute from '@/router/guards/FolderRoute';
import WebshopRoute from '@/router/guards/WebshopRoute';
import BrandRoute from '@/router/guards/BrandRoute';
import ProductRoute from '@/router/guards/ProductRoute';
import ReferralSystem from '@/router/guards/ReferralSystem';
import ArticleCategoryRoute from '@/router/guards/ArticleCategoryRoute';
import ArticleRoute from '@/router/guards/ArticleRoute';
import NewsletterToken from '@/router/guards/NewsletterToken';
import PageRoute from '@/router/guards/PageRoute';

//views
import HomeView from '@/views/feed/HomeView';
import PrizePoolView from '@/views/prizes/PrizePoolView';
import ProfileView from '@/views/profile/ProfileView';
import PromosView from '@/views/feed/promos/PromosView';
import PrizeRequestView from '@/views/prizes/PrizeRequestView';
import SearchView from '@/views/feed/SearchView';
import SettingsView from '@/views/feed/SettingsView';
import FoldersView from '@/views/folders/FoldersView';
import FoldersStoreView from '@/views/folders/FoldersStoreView';
import FolderView from '@/views/folders/FolderView';
import WebshopsView from '@/views/groups/WebshopsView';
import StoresView from '@/views/groups/StoresView';
import BrandsView from '@/views/groups/BrandsView';
import ProductsView from '@/views/groups/ProductsView';
import WebshopView from '@/views/groups/single/WebshopView';
import StoreView from '@/views/groups/single/StoreView';
import BrandView from '@/views/groups/single/BrandView';
import ProductView from '@/views/groups/single/ProductView';
import FavouritesView from '@/views/feed/FavouritesView';
import ResetPasswordView from '@/views/auth/ResetPasswordView';
import PageNotFoundHandlerView from '@/views/PageNotFoundHandlerView';
import ReferralView from '@/views/bonuses/ReferralView';
import GoToView from '@/views/GoToView';
import VerifyAccountView from '@/views/auth/VerifyAccountView';
import NewsView from '@/views/news/NewsView';
import NewsCategoryView from '@/views/news/NewsCategoryView';
import ArticleView from '@/views/news/ArticleView';
import LandingView from '@/views/feed/LandingView.vue';
import NotificationsView from '@/views/feed/NotificationsView.vue';
import FolderPromoView from '@/views/feed/promos/FolderPromoView';
import FolderItemRoute from '@/router/guards/FolderItemRoute';
import OnlinePromoView from '@/views/feed/promos/OnlinePromoView';
import OnlinePromoRoute from '@/router/guards/OnlinePromoRoute';
import PostRoute from '@/router/guards/PostRoute';
import PostView from '@/views/feed/promos/PostView';
import PromosRoute from '@/router/guards/PromosRoute';

const routes = [
    // {path: '/login', redirect: {name: 'home'}},
    {
        path: '/login',
        name: 'login',
        component: HomeView,
        beforeEnter: [Guest],
        meta: {
            breadcrumb(route) {
                if (this.xsOnly) {
                    return [];
                }
                return [
                    {
                        text: store.getters['auth/I18N'].t('menu.main.home'),
                        disabled: false,
                        href: '/',
                    },
                    {
                        text: store.getters['auth/I18N'].t('auth.login'),
                        disabled: true,
                    }
                ];
            },
        },
    },
    {
        path: '/register',
        name: 'register',
        component: HomeView,
        beforeEnter: [Guest],
        meta: {
            breadcrumb(route) {
                if (this.xsOnly) {
                    return [];
                }
                return [
                    {
                        text: store.getters['auth/I18N'].t('menu.main.home'),
                        disabled: false,
                        href: '/',
                    },
                    {
                        text: store.getters['auth/I18N'].t('auth.register_for_free'),
                        disabled: true,
                    }
                ];
            },
        },
    },
    {
        path: '/',
        name: 'home',
        component: LandingView,
        beforeEnter: [ReferralSystem, NewsletterToken],
        meta: {
            breadcrumb: [],
            title() {
                return store.getters['auth/I18N'].t('general.home_meta.title');
            },
            description() {
                return store.getters['auth/I18N'].t('general.home_meta.description');
            },
        },
    },
    {
        path: '/feed',
        name: 'feed',
        component: HomeView,
        beforeEnter: [ReferralSystem],
        meta: {
            breadcrumb: [],
            title() {
                return store.getters['auth/I18N'].t('general.home_meta.title');
            },
            description() {
                return store.getters['auth/I18N'].t('general.home_meta.description');
            },
        },
    },
    {
        path: '/password/reset',
        name: 'password.reset',
        component: ResetPasswordView,
        beforeEnter: [Guest],
        meta: {
            breadcrumb: [],
        },
    },
    {
        path: '/verwijs-een-vriend',
        name: 'referral_page',
        component: ReferralView,
        beforeEnter: [Auth],
        meta: {
            breadcrumb(route) {
                return [
                    {
                        text: store.getters['auth/I18N'].t('menu.main.home'),
                        disabled: false,
                        href: '/',
                    },
                    {
                        text: store.getters['auth/I18N'].t('menu.main.invite_friend'),
                        disabled: true,
                    }
                ];
            },
            title() {
                return store.getters['auth/I18N'].t('general.referral_page_meta.title');
            },
        },
    },
    {
        path: '/profiel/:userId',
        name: 'profile',
        component: ProfileView,
        beforeEnter: [],

        meta: {
            breadcrumb(route) {
                return [
                    {
                        text: store.getters['auth/I18N'].t('menu.main.home'),
                        disabled: false,
                        href: '/',
                    },
                    {
                        text: store.getters['auth/I18N'].t('menu.user.profile'),
                        disabled: true,
                    }
                ];
            },
        }
    },
    {
        path: '/favorieten',
        name: 'favourites',
        component: FavouritesView,
        beforeEnter: [Auth],
        meta: {
            breadcrumb(route) {
                return [
                    {
                        text: store.getters['auth/I18N'].t('menu.main.home'),
                        disabled: false,
                        href: '/',
                    },
                    {
                        text: store.getters['auth/I18N'].t('menu.user.favourites'),
                        disabled: true,
                    },
                ];
            },
        },
    },
    {
        path: '/settings',
        name: 'settings',
        component: SettingsView,
        beforeEnter: [Auth],
        meta: {
            breadcrumb(route) {
                return [
                    {
                        text: store.getters['auth/I18N'].t('menu.main.home'),
                        disabled: false,
                        href: '/',
                    },
                    {
                        text: store.getters['auth/I18N'].t('menu.user.settings'),
                        disabled: true,
                    },
                ];
            },
        },
    },
    {
        path: '/notifications',
        name: 'notifications',
        component: NotificationsView,
        beforeEnter: [Auth],
        meta: {
            breadcrumb: [],
        }
    },
    {
        path: '/prijzenpot',
        name: 'prizes-pool',
        component: PrizePoolView,
        beforeEnter: [Auth],
        meta: {
            breadcrumb(route) {
                return [
                    {
                        text: store.getters['auth/I18N'].t('prizes.account'),
                        disabled: false,
                        href: '/',
                    },
                    {
                        text: store.getters['auth/I18N'].t('prizes.prizes_pool'),
                        disabled: true,
                    },
                ];
            },
            title() {
                return store.getters['auth/I18N'].t('general.prizes_meta.title');
            },
            description() {
                return store.getters['auth/I18N'].t('general.prizes_meta.description');
            },
        },
    },
    {
        path: '/prijzenpot/:prizeId',
        name: 'prizes-pool.prize-request',
        component: PrizeRequestView,
        beforeEnter: [Auth],
        meta: {
            breadcrumb(route) {
                return [
                    {
                        text: store.getters['auth/I18N'].t('prizes.account'),
                        disabled: false,
                        href: '/',
                    },
                    {
                        text: store.getters['auth/I18N'].t('prizes.prizes_pool'),
                        disabled: false,
                        href: '/prijzenpot',
                    },
                    {
                        text: store.getters['auth/I18N'].t('prizes.request_prize'),
                        disabled: true,
                    },
                ];
            },
            title() {
                return store.getters['auth/I18N'].t('general.prizes_meta.title');
            },
            description() {
                return store.getters['auth/I18N'].t('general.prizes_meta.description');
            },
        },
    },
    {
        path: '/promos/:categorySlug?',
        name: 'promos',
        component: PromosView,
        beforeEnter: [PromosRoute],
        meta: {
            breadcrumb(route) {
                if (this.xsOnly) {
                    return [];
                }

                return [
                    {
                        text: store.getters['auth/I18N'].t('menu.main.home'),
                        disabled: false,
                        href: '/',
                    },
                    {
                        text: store.getters['auth/I18N'].t('menu.main.promos'),
                        disabled: true,
                    },
                ];
            },
            title() {
                const title = store.getters['auth/I18N'].t('general.promos_meta.title', {
                    date: dates.currentDateFormatted(),
                });

                if (_.has(store.getters['category/CURRENT_CATEGORY'], 'translation.title')) {
                    return _.capitalize(store.getters['category/CURRENT_CATEGORY'].translation.title)
                        + ' ' + title;
                }

                return title;
            },
            description() {
                return store.getters['auth/I18N'].t('general.promos_meta.description', {
                    date: dates.currentDateFormatted(),
                });
            },
        },
    },
    {
        path: '/folder-promos/:id',
        name: 'folder_promos.show',
        component: FolderPromoView,
        beforeEnter: [FolderItemRoute],
        meta: {
            breadcrumb(route) {
                return [
                    {
                        text: store.getters['auth/I18N'].t('menu.main.home'),
                        disabled: false,
                        href: '/',
                    },
                    {
                        text: store.getters['auth/I18N'].t('menu.main.promos'),
                        disabled: false,
                        href: '/promos',
                    },
                    {
                        text: store.getters['promo/CURRENT_PROMO'].title,
                        disabled: true,
                    },
                ];
            },
            title() {
                return store.getters['auth/I18N'].t('general.promos_meta.title', {
                    date: dates.currentDateFormatted(),
                });
            },
            description() {
                return store.getters['auth/I18N'].t('general.promos_meta.description', {
                    date: dates.currentDateFormatted(),
                });
            },
        },
    },
    {
        path: '/online-promos/:id',
        name: 'online_promos.show',
        component: OnlinePromoView,
        beforeEnter: [OnlinePromoRoute],
        meta: {
            breadcrumb(route) {
                return [
                    {
                        text: store.getters['auth/I18N'].t('menu.main.home'),
                        disabled: false,
                        href: '/',
                    },
                    {
                        text: store.getters['auth/I18N'].t('menu.main.promos'),
                        disabled: false,
                        href: '/promos',
                    },
                    {
                        text: store.getters['promo/CURRENT_PROMO'].title,
                        disabled: true,
                    },
                ];
            },
            title() {
                return store.getters['auth/I18N'].t('general.promos_meta.title', {
                    date: dates.currentDateFormatted(),
                });
            },
            description() {
                return store.getters['auth/I18N'].t('general.promos_meta.description', {
                    date: dates.currentDateFormatted(),
                });
            },
        },
    },
    {
        path: '/posts/:id',
        name: 'posts.show',
        component: PostView,
        beforeEnter: [PostRoute],
        meta: {
            breadcrumb(route) {
                return [
                    {
                        text: store.getters['auth/I18N'].t('menu.main.home'),
                        disabled: false,
                        href: '/',
                    },
                    {
                        text: store.getters['auth/I18N'].t('menu.main.promos'),
                        disabled: false,
                        href: '/promos',
                    },
                    {
                        text: post.getTitle(store.getters['promo/CURRENT_PROMO']),
                        disabled: true,
                    },
                ];
            },
            title() {
                return store.getters['auth/I18N'].t('general.promos_meta.title', {
                    date: dates.currentDateFormatted(),
                });
            },
            description() {
                return store.getters['auth/I18N'].t('general.promos_meta.description', {
                    date: dates.currentDateFormatted(),
                });
            },
        },
    },
    {
        path: '/nieuws',
        name: 'news',
        component: NewsView,
        beforeEnter: [],
        meta: {
            breadcrumb: [],
            title() {
                return store.getters['auth/I18N'].t('news.meta_title');
            },
            description() {
                return store.getters['auth/I18N'].t('news.meta_description');
            },
        },
    },
    {
        path: '/nieuws/:categorySlug',
        name: 'news.category',
        component: NewsCategoryView,
        beforeEnter: [ArticleCategoryRoute],
        meta: {
            breadcrumb: [],
            title() {
                return store.getters['article_category/CURRENT_ARTICLE_CATEGORY'].translation.title;
            },
            description() {
                return store.getters['auth/I18N'].t('news.meta_description');
            },
        },
    },
    {
        path: '/nieuws/:categorySlug/:articleSlug',
        name: 'news.article',
        component: ArticleView,
        beforeEnter: [ArticleCategoryRoute, ArticleRoute],
        meta: {
            breadcrumb: [],
        },
    },
    {
        path: '/search',
        name: 'search',
        component: SearchView,
        beforeEnter: [],
        meta: {
            breadcrumb(route) {
                return [
                    {
                        text: store.getters['auth/I18N'].t('menu.main.home'),
                        disabled: false,
                        href: '/',
                    },
                    {
                        text: store.getters['auth/I18N'].t('search.search_result'),
                        disabled: true,
                    },
                ];
            },
        },
    },
    {
        path: '/folders',
        name: 'folders',
        component: FoldersView,
        beforeEnter: [],
        meta: {
            breadcrumb(route) {
                if (this.xsOnly) {
                    return [];
                }

                return [
                    {
                        text: store.getters['auth/I18N'].t('menu.main.home'),
                        disabled: false,
                        href: '/',
                    },
                    {
                        text: store.getters['auth/I18N'].t('folder.folders'),
                        disabled: true,
                    },
                ];
            },
            title() {
                return store.getters['auth/I18N'].t('general.folders_meta.title');
            },
            description() {
                return store.getters['auth/I18N'].t('general.folders_meta.description');
            },
        },
    },
    {
        path: '/folders/:storeSlug',
        name: 'folders.store',
        component: FoldersStoreView,
        beforeEnter: [StoreRoute],
        meta: {
            breadcrumb(route) {
                return [
                    {
                        text: store.getters['auth/I18N'].t('menu.main.home'),
                        disabled: false,
                        href: '/',
                    },
                    {
                        text: store.getters['auth/I18N'].t('folder.folders'),
                        disabled: false,
                        href: '/folders',
                    },
                    {
                        text: store.getters['store/CURRENT_STORE'].translation.title,
                        disabled: true,
                    },
                ];
            },
            title() {
                return store.getters['auth/I18N'].t(
                    'general.folders_store_meta.title',
                    {
                        store: store.getters['store/CURRENT_STORE'].translation.title,
                    }
                );
            },
            description() {
                return store.getters['auth/I18N'].t(
                    'general.folders_store_meta.description',
                    {
                        store: store.getters['store/CURRENT_STORE'].translation.title,
                    }
                );
            },
        },
    },
    {
        path: '/folders/:storeSlug/:folderDate',
        name: 'folder.view',
        component: FolderView,
        beforeEnter: [StoreRoute, FolderRoute],
        meta: {
            breadcrumb(route) {
                const storeLink = this.$router.resolve({
                    name: 'folders.store',
                    params: {
                        storeSlug: store.getters['store/CURRENT_STORE'].translation.route,
                    },
                });
                return [
                    {
                        text: store.getters['auth/I18N'].t('menu.main.home'),
                        disabled: false,
                        href: '/',
                    },
                    {
                        text: store.getters['auth/I18N'].t('folder.folders'),
                        disabled: false,
                        href: '/folders',
                    },
                    {
                        text: store.getters['store/CURRENT_STORE'].translation.title,
                        disabled: false,
                        href: storeLink.href,
                    },
                    {
                        text: route.params.folderDate,
                        disabled: true,
                    },
                ];
            },
            title() {
                return store.getters['auth/I18N'].t('general.folder_meta.title', {
                    title: store.getters['store/CURRENT_STORE'].translation.title,
                    valid_from: dates.dateToFormatted(
                        store.getters['folder/CURRENT_FOLDER'].valid_from
                    ),
                    valid_to: dates.dateToFormatted(
                        store.getters['folder/CURRENT_FOLDER'].valid_to
                    ),
                });
            },
            description() {
                return store.getters['auth/I18N'].t('general.folder_meta.description', {
                    title: store.getters['store/CURRENT_STORE'].translation.title,
                    valid_from: dates.dateToFormatted(
                        store.getters['folder/CURRENT_FOLDER'].valid_from
                    ),
                    valid_to: dates.dateToFormatted(
                        store.getters['folder/CURRENT_FOLDER'].valid_to
                    ),
                });
            },
        },
    },
    {
        path: '/webshop',
        name: 'webshops',
        component: WebshopsView,
        beforeEnter: [],
        meta: {
            breadcrumb: [],
            title() {
                return store.getters['auth/I18N'].t('general.webshops_meta.title');
            },
            description() {
                return store.getters['auth/I18N'].t(
                    'general.webshops_meta.description'
                );
            },
        },
    },
    {
        path: '/webshop/:webshopSlug',
        name: 'webshop.show',
        component: WebshopView,
        beforeEnter: [WebshopRoute],
        meta: {
            breadcrumb(route) {
                return [
                    {
                        text: store.getters['auth/I18N'].t('menu.main.home'),
                        disabled: false,
                        href: '/',
                    },
                    {
                        text: store.getters['auth/I18N'].t('webshop.all_webshops'),
                        disabled: false,
                        href: '/webshop',
                    },
                    {
                        text: store.getters['webshop/CURRENT_WEBSHOP'].translation.title,
                        disabled: true,
                        href:
                            '/webshop/' +
                            store.getters['webshop/CURRENT_WEBSHOP'].translation.route,
                    },
                ];
            },
            title() {
                return store.getters['auth/I18N'].t('general.webshop_meta.title', {
                    webshop: store.getters['webshop/CURRENT_WEBSHOP'].translation.title,
                    date: dates.currentDateFY(),
                });
            },
            description() {
                return store.getters['auth/I18N'].t(
                    'general.webshop_meta.description',
                    {
                        webshop: store.getters['webshop/CURRENT_WEBSHOP'].translation.title,
                        date: dates.currentDateFY(),
                    }
                );
            },
        },
    },
    {
        path: '/store',
        name: 'stores',
        component: StoresView,
        beforeEnter: [],
        meta: {
            breadcrumb: [],
            title() {
                return store.getters['auth/I18N'].t('general.stores_meta.title');
            },
            description() {
                return store.getters['auth/I18N'].t('general.stores_meta.description');
            },
        },
    },
    {
        path: '/store/:storeSlug',
        name: 'store.show',
        component: StoreView,
        beforeEnter: [StoreRoute],
        meta: {
            breadcrumb(route) {
                return [
                    {
                        text: store.getters['auth/I18N'].t('menu.main.home'),
                        disabled: false,
                        href: '/',
                    },
                    {
                        text: store.getters['auth/I18N'].t('store.all_stores'),
                        disabled: false,
                        href: '/store',
                    },
                    {
                        text: store.getters['store/CURRENT_STORE'].translation.title,
                        disabled: true,
                        href:
                            '/store/' +
                            store.getters['store/CURRENT_STORE'].translation.route,
                    },
                ];
            },
            title() {
                return store.getters['auth/I18N'].t('general.store_meta.title', {
                    store: store.getters['store/CURRENT_STORE'].translation.title,
                    date: dates.currentDateFY(),
                });
            },
            description() {
                return store.getters['auth/I18N'].t('general.store_meta.description', {
                    store: store.getters['store/CURRENT_STORE'].translation.title,
                    date: dates.currentDateFY(),
                });
            },
        },
    },
    {
        path: '/brand',
        name: 'brands',
        component: BrandsView,
        beforeEnter: [],
        meta: {
            breadcrumb: [],
            title() {
                return store.getters['auth/I18N'].t('general.brands_meta.title');
            },
            description() {
                return store.getters['auth/I18N'].t('general.brands_meta.description');
            },
        },
    },
    {
        path: '/brand/:brandSlug',
        name: 'brand.show',
        component: BrandView,
        beforeEnter: [BrandRoute],
        meta: {
            breadcrumb(route) {
                return [
                    {
                        text: store.getters['auth/I18N'].t('menu.main.home'),
                        disabled: false,
                        href: '/',
                    },
                    {
                        text: store.getters['auth/I18N'].t('brand.all_brands'),
                        disabled: false,
                        href: '/brand',
                    },
                    {
                        text: store.getters['brand/CURRENT_BRAND'].translation.title,
                        disabled: true,
                        href:
                            '/brand/' +
                            store.getters['brand/CURRENT_BRAND'].translation.route,
                    },
                ];
            },
            title() {
                return store.getters['auth/I18N'].t('general.brand_meta.title', {
                    brand: store.getters['brand/CURRENT_BRAND'].translation.title,
                    date: dates.currentDateFY(),
                });
            },
            description() {
                return store.getters['auth/I18N'].t('general.brand_meta.description', {
                    brand: store.getters['brand/CURRENT_BRAND'].translation.title,
                    date: dates.currentDateFY(),
                });
            },
        },
    },
    {
        path: '/product',
        name: 'products',
        component: ProductsView,
        beforeEnter: [],
        meta: {
            breadcrumb: [],
            title() {
                return store.getters['auth/I18N'].t('general.products_meta.title');
            },
            description() {
                return store.getters['auth/I18N'].t(
                    'general.products_meta.description'
                );
            },
        },
    },
    {
        path: '/product/:productSlug',
        name: 'product.show',
        component: ProductView,
        beforeEnter: [ProductRoute],
        meta: {
            breadcrumb(route) {
                return [
                    {
                        text: store.getters['auth/I18N'].t('menu.main.home'),
                        disabled: false,
                        href: '/',
                    },
                    {
                        text: store.getters['auth/I18N'].t('product.all_products'),
                        disabled: false,
                        href: '/product',
                    },
                    {
                        text: store.getters['product/CURRENT_PRODUCT'].translation.title,
                        disabled: true,
                        href:
                            '/product/' +
                            store.getters['product/CURRENT_PRODUCT'].translation.route,
                    },
                ];
            },
            title() {
                return store.getters['auth/I18N'].t('general.product_meta.title', {
                    product: store.getters['product/CURRENT_PRODUCT'].translation.title,
                    date: dates.currentDateFY(),
                });
            },
            description() {
                return store.getters['auth/I18N'].t(
                    'general.product_meta.description',
                    {
                        product: store.getters['product/CURRENT_PRODUCT'].translation.title,
                        date: dates.currentDateFY(),
                    }
                );
            },
        },
    },
    {
        path: '/verify',
        component: VerifyAccountView,
        name: 'verify',
    },
    {
        path: '/goto/:key',
        component: GoToView,
        name: 'goto',
    },
    // and finally the default route, when none of the above matches:
    {
        path: '/:pathMatch(.*)*',
        component: PageNotFoundHandlerView,
        beforeEnter: [PageRoute],
        name: 'not_found',
        meta: {
            breadcrumb(route) {
                if (!store.getters['page/CURRENT_PAGE']) {
                    return [];
                }
                return [
                    {
                        text: store.getters['auth/I18N'].t('menu.main.home'),
                        disabled: false,
                        href: '/',
                    },
                    {
                        text: store.getters['page/CURRENT_PAGE'].title,
                        disabled: true,
                    }
                ];
            },
        },
    },
];

export default routes;
