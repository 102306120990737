<template>
  <div class="centered px-2">
    <button class="w-100 webshop-button"
            type="primary"
            ref="main_button"
            @click="openLink"
    >
      {{ $t('post.go_webshop_btn') }}
    </button>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'go-webshop-button',
  props: {
    url: {
      type: String
    },
    eventData: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  computed: {
    ...mapGetters('auth', [
      'USER',
    ]),
  },
  methods: {
    openLink() {
      if (_.has(this.eventData, 'event')) {
        const eventDataToPush = this.eventData;
        eventDataToPush.user_id = _.get(this.USER, 'id', 0)
        eventDataToPush.clicked_link = this.url;
        eventDataToPush.clicked_text = this.$refs.main_button.innerText;

        this.processEvent(eventDataToPush);

        setTimeout(() => {
          window.open(this.url, '_blank');
        }, 300); // 300ms should generally be enough
      } else {
        window.open(this.url, '_blank');
      }
    }
  }
}
</script>
