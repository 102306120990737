export const gtm_events = {
    methods: {
        processEvent(eventDataToPush) {
            window.dataLayer?.push(eventDataToPush);
            if (_.has(eventDataToPush, 'post_id')) {
                console.log('post click event');
                axios.put(`/posts/${eventDataToPush.post_id}/add-click`);
            }
            if (_.has(eventDataToPush, 'folder_item_id')) {
                console.log('folder click event');
                axios.put(`/folder-items/${eventDataToPush.folder_item_id}/add-click`);
            }
            if (_.has(eventDataToPush, 'online_promo_id')) {
                console.log('online promo click event');
                axios.put(`/online-promos/${eventDataToPush.online_promo_id}/add-click`);
            }
        }
    },
};
