<template>
  <div class="post-horizontal">
    <div
      v-if="isBangPromo"
      class="bang-promo"
      @click="showFullPromo"
      @mouseenter="bangPromoHover = true"
      @mouseleave="bangPromoHover = false"
    />
    <div v-if="upvoteAnimation" class="upvote-animation">
      <img src="@/assets/images/firework.gif" class="w-100 h-100" alt="">
    </div>

    <!--
      Header
    -->
    <div class="post-horizontal__header">
      <div class="favorite-circle">
        <i
          :class="{'active icon-heart-2': post.is_favourite, 'icon-heart': !post.is_favourite}"
          @click="processFavourite"
        />
      </div>
    </div>

    <div class="d-flex border-box">
      <!--
         Image
      -->
      <div class="post-horizontal__image" v-loading="imageIsLoading">
        <img
          :src="(imageLink && !imageLoadError) ? imageLink : require('@/assets/images/promo_code.jpg')"
          :class="{'hover': bangPromoHover}"
          @load="imageIsLoading = false"
          @error="imageLoadError = true"
          @click="showFullPromo"
          alt=""
          :style="imageStyles"
        />
      </div>

      <!--
         Content
      -->
      <div class="post-horizontal__content">
        <div class="w-100 align-self-start">
          <div class="price" v-if="post.new_price || post.discount || post.discount_text">
            <span class="hyphen me-1">&#8212;</span>

            <template v-if="post.new_price">
              <template v-if="post.old_price">
                <span class="price--old">€ {{ post.old_price }}</span>
                <span class="price--separator"> | </span>
              </template>
              <span class="price--new">€ {{ post.new_price }}</span>
            </template>

            <span v-else-if="post.discount" class="price--discount">{{ $t('post.discount') }}: {{
                post.discount
              }}</span>
            <span v-else-if="post.discount_text" class="price--discount">{{ post.discount_text }}</span>

            <span class="hyphen ms-1">&#8212;</span>
          </div>
        </div>

        <p v-if="title" class="title" v-html="title"/>
        <button class="more" @click="showFullPromo">{{ $t('post.meer_detail') }}</button>

        <button class="look-promo" @click="btnClicked">
          {{ hasDiscountCode ? $t('post.discount_btn') : $t('post.look_promo') }}
        </button>

        <div class="w-100 d-flex flex-row flex-md-column justify-content-between border-box">
          <author-info
            class="flex-grow-1 justify-content-start justify-content-md-center"
            :avatar="post.user?.avatar_url ?? require('@/assets/images/fb_logo.png')"
            :name="post.user?.full_name ?? 'Promojager'"
            :profile-id="post.user?.id"
            :profileType="'user'"
            :valid_text="post.valid_text"
            :is-valid="!post.is_expired"
          />

          <share btn-class="d-flex align-items-center ps-1" @share="processShare"/>
        </div>
      </div>
    </div>

    <!--
      Footer
    -->
    <div class="post-horizontal__footer">
      <votes
        :is-like="post.is_hit"
        :is-dislike="post.is_dislike"
        :rating="post.rating_calculated"
        @like="makeHit"
        @dislike="makeDislike"
      />
    </div>
  </div>
</template>

<script>
import post from '@/plugins/post';
import {needAuth} from '@/mixins/auth/needAuth';
import {actions} from '@/mixins/post/actions';
import {share} from '@/mixins/post/share';
import Votes from '@/components/feed/Votes.vue';
import Share from '@/components/feed/Share.vue';

export default {
  name: 'post-horizontal',
  components: {Share, Votes},
  mixins: [actions, needAuth, share],
  props: {
    post: Object,
    isBangPromo: {
      type: Boolean,
      default: false
    },
    showPromoman: {
      type: Boolean,
      default: false
    },
    vuexModule: {
      type: String,
      default: 'promo',
    },
  },
  data() {
    return {
      imageLoadError: false,
      imageIsLoading: true,
      bangPromoHover: false,
      upvoteAnimation: false,
      isImageHovered: false,
    }
  },
  computed: {

    detections() {
      if (this.post.images.length) {
        return this.post.images[0].detections;
      }
      return null;
    },

    imageStyles() {
      if (this.detections) {
        const clipPath = `polygon(${this.detections.polygon})`;
        const transform = `translate(${this.detections.transformX}%, ${this.detections.transformY}%)`;

        return {
          'clip-path': clipPath,
          'transform': transform,
          width: `${this.detections.width}%`,
          height: 'auto',
        };
      }
      return {};
    },

    imageLink() {
      if (this.post.images.length) {
        return this.post.images[0].src;
      }
      if (this.post.webshop) {
        return this.post.webshop.image_url;
      }
      return null;
    },
    /**
     * Data for mixin
     * @returns {{morphable_type: string, entity: Object}}
     */
    entityData() {
      return {
        entity: this.post,
        morphable_type: 'Post'
      }
    },
    /**
     * Post title
     * @returns {*|string}
     */
    title() {
      return post.getTitle(this.post);
    },
    hasDiscountCode() {
      return this.post.discount_code || this.post.discount_codes_count
    },
  },
  methods: {
    processShare(destination) {
      const link = new URL(
          this.$router.resolve({
            name: 'posts.show',
            params: {id: this.post.id}
          }).href,
          window.location.origin
      ).href;
      this.shareLink(link, destination);
    },
    makeHit() {
      const isHit = this.post.is_hit;
      const thisElem = this

      this.processHit()?.then(() => {
        thisElem.$emit('voted')

        setTimeout(() => {
          if (!isHit && thisElem.isBangPromo) {
            thisElem.enableUpvoteAnimation()
          }
        }, 200);
      })
    },
    makeDislike() {
      const thisElem = this

      this.processDislike()?.then(function () {
        thisElem.$emit('voted')
      })
    },
    enableUpvoteAnimation() {
      this.upvoteAnimation = true;
      setTimeout(() => this.upvoteAnimation = false, 4200)
    },
    btnClicked() {
      if (this.hasDiscountCode) {
        this.openDiscount()
      } else {
        this.showFullPromo()
      }
    },
    showFullPromo() {
      this.$emit('opened')
      this.routerPush({name: 'posts.show', params: {id: String(this.post.id)}});
    },
    openDiscount() {
      if (this.hasDiscountCode) {
        this.emitter.emit('copy-discount-code', {
          data: {discount_url: this.post.discount_url, discount_code: this.post.discount_code, id: this.post.id},
          eventData: this.post.meta_properties
        });
        return;
      }

      this.axios.put('/posts/' + this.post.id + '/discount-code').then((response) => {
        this.emitter.emit('copy-discount-code', {
          data: {
            discount_url: this.post.discount_url,
            discount_code: response.data.data.discount_code,
            id: this.post.id
          },
          eventData: this.post.meta_properties
        });
      }).catch((e) => {
        if (404 === e.response.status) {
          alert('No available codes');
        }
      });
    }
  }
}
</script>
