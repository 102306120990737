<template>
  <div class="article-feed">
    <el-row type="flex" justify="start">
      <el-col :xs="24" :sm="24" :md="16" :lg="16" :xl="16">
        <div class="article-feed-container">
          <div class="article-image-content">
            <!--    Favorite icon    -->
            <div class="favorite-circle">
              <i
                  :class="{'active icon-heart-2': CURRENT_ARTICLE.is_favourite, 'icon-heart': !CURRENT_ARTICLE.is_favourite}"
                  @click="processFavourite"
              />
            </div>

            <img v-if="CURRENT_ARTICLE.translation.additional_image" :src="CURRENT_ARTICLE.translation.additional_image"
                 class="article-image">
            <img v-else-if="CURRENT_ARTICLE.translation.cover_image" :src="CURRENT_ARTICLE.translation.cover_image"
                 class="article-image">
            <img v-else src="@/assets/images/promo-blog.png"
                 class="article-image">
          </div>
          <div class="article-avatar-content">
            <profile-preview-dialog :profile-id="CURRENT_ARTICLE.user.id">
              <el-avatar
                  :src="CURRENT_ARTICLE.user.avatar_url"
                  shape="circle"
                  class="article-author-avatar cursor-pointer"
                  @mouseover="mouseHoverProfile = true"
                  @mouseleave="mouseHoverProfile = false"
              >
              </el-avatar>

              <div class="article-author">
                <div
                    class="article-author-name cursor-pointer"
                    :class="{'text-decoration-underline': mouseHoverProfile}"
                    v-text="CURRENT_ARTICLE.article_author_name"
                    @mouseover="mouseHoverProfile = true"
                    @mouseleave="mouseHoverProfile = false"
                >
                </div>
                <div class="article-time" v-text="CURRENT_ARTICLE.edited_date_text"></div>
              </div>
            </profile-preview-dialog>
          </div>
          <div class="article-title-content">
            {{ CURRENT_ARTICLE.translation.title }}
          </div>
          <div class="article-main-content">
            <div v-html="CURRENT_ARTICLE.translation.content"></div>
          </div>
        </div>
      </el-col>
      <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
        <news-left-bottom-bar :last-views="LAST_MONTH"></news-left-bottom-bar>
      </el-col>
    </el-row>

    <div>
      <h4 class="most-views-header text-center" v-if="topArticlesCurrentCategory.length > 0"
          v-html="$t('news.interesting_for_you_too')"></h4>
      <el-row type="flex" justify="start">
        <template v-for="article in topArticlesCurrentCategory" :key="article.id">
          <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
            <a v-if="article.is_external" v-bind:href="article.translation.external_link"
               class="p-3 most-views-article-block" target="_blank" rel="noopener noreferrer">
              <div class="article-image-content">
                <img v-if="article.translation.cover_image" :src="article.translation.cover_image"
                     class="article-image middle">
                <img v-else src="@/assets/images/promo-blog.png"
                     class="article-image middle">
              </div>
              <div class="most-views-article-title py-2">
                {{ article.translation.title }}
              </div>
            </a>
            <router-localized-link v-else class="p-3 most-views-article-block" :to="articleRoute">
              <div class="article-image-content">
                <img v-if="article.translation.cover_image" :src="article.translation.cover_image"
                     class="view-article-image">
                <img v-else src="@/assets/images/promo-blog.png"
                     class="view-article-image">
              </div>
              <div class="most-views-article-title py-2">
                {{ article.translation.title }}
              </div>
            </router-localized-link>
          </el-col>
        </template>
      </el-row>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import NewsLeftBottomBar from '@/components/feed/news/NewsLeftBottomBar';
import {dates} from '@/mixins/dates';
import RouterLocalizedLink from '@/components/common/RouterLocalizedLink';
import ProfilePreviewDialog from '@/components/feed/profile/ProfilePreviewDialog';
import {needAuth} from '@/mixins/auth/needAuth';
import {useHead} from '@vueuse/head';

export default {
  name: 'ArticleFeed',
  components: {
    NewsLeftBottomBar,
    RouterLocalizedLink,
    ProfilePreviewDialog
  },
  mixins: [dates, needAuth],
  data() {
    return {
      mouseHoverProfile: false,
    }
  },
  computed: {
    ...mapGetters('article', [
      'CURRENT_ARTICLE',
      'LAST_MONTH'
    ]),

    ...mapGetters('article_category', [
      'CURRENT_ARTICLE_CATEGORY',
    ]),

    topArticlesCurrentCategory() {
      return _.get(this.CURRENT_ARTICLE_CATEGORY, 'articles', []);
    },
    articleRoute() {
      return {
        name: 'news.article',
        params: {
          categorySlug: String(this.CURRENT_ARTICLE_CATEGORY.translation.slug),
          articleSlug: String(this.CURRENT_ARTICLE.translation.slug),
        }
      };
    },
  },
  created() {
    useHead({
      // Can be static or computed
      script: [
        {
          type: 'application/ld+json',
          children: JSON.stringify(this.getArticleStructure())
        },

      ],
    })
  },
  methods: {
    processFavourite() {
      if (this.triggerLoginDialog()) {
        return;
      }

      if (this.CURRENT_ARTICLE.is_favourite) {
        this.$store.dispatch('article/REMOVE_FAVORITE', this.CURRENT_ARTICLE.id);
      } else {
        this.$store.dispatch('article/SET_FAVORITE', this.CURRENT_ARTICLE.id)
      }
    },
    getArticleStructure() {
      return {
        '@context': 'https://schema.org',
        '@type': 'Article',
        'headline': this.CURRENT_ARTICLE.translation?.title ,
        'image': this.CURRENT_ARTICLE.translation?.cover_image
            ? this.CURRENT_ARTICLE.translation.cover_image
            : `https://${process.env.VUE_APP_DOMAIN}/images/blogs/promo-blog-lg.png`,
        'datePublished': this.CURRENT_ARTICLE.published_at?.iso?.split('T')[0],
        'dateModified': this.CURRENT_ARTICLE.updated_at?.iso?.split('T')[0],
        'author': {
          '@type': 'Person',
          'name': this.CURRENT_ARTICLE.article_author_name || 'Anonymous'
        },
        'publisher': {
          '@type': 'Organization',
          'name':  'PromoJagers',
          'logo': {
            '@type': 'ImageObject',
            'url': `https://${process.env.VUE_APP_DOMAIN}/images/logo.jpg`,
          },
        },
        'description': this.CURRENT_ARTICLE.translation?.meta_description
      };
    },
  },
}
</script>
