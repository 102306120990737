<template>
  <el-dialog
    v-model="visible"
    :lock-scroll="!xsOnly"
    modal-class="user-menu"
    close-on-press-escape
    close-on-click-modal
    show-close
    center
  >
    <template #header>
      <div class="menu-header-wrapper" ref="user_menu_header">
        <div class="menu-header">
          <router-localized-link
            class="d-flex align-items-center font-weight-medium"
            :to="{name: 'profile', params: {userId: String(USER.id)}}"
          >
            <el-avatar :src="USER.avatar_url" shape="circle" :size="50" class="me-3"/>
            <span class="hover:color-primary">{{ USER.fname }}</span>
          </router-localized-link>
          <div class="font-weight-bold">&nbsp;&#183;&nbsp;</div>
          <router-localized-link class="font-weight-600 hover:color-primary" :to="{name: 'prizes-pool'}">
            {{ $t('menu.user.points_header', {points: USER.account.available}) }}
          </router-localized-link>
        </div>

        <locale-switcher v-if="!xsOnly"/>
      </div>
    </template>

    <!--
      Menu items
    -->
    <div class="menu-items-wrapper" ref="user_menu_body">
      <!--    Favourites    -->
      <div class="menu-item" >
        <div class="icon-wrapper">
          <i class="icon-heart-2"/>
        </div>
        <router-localized-link :to="{name: 'favourites'}">
          <span class="" v-t="'menu.user.favourites'"/>
        </router-localized-link>
      </div>

      <!--    Prizes    -->
      <div class="menu-item menu-delimiter">
        <div class="icon-wrapper">
          <i class="icon-cup"/>
        </div>
        <router-localized-link :to="{name: 'prizes-pool'}">
          <span v-t="'menu.main.prizes'"/>
        </router-localized-link>
      </div>

      <!--   Promos   -->
      <div class="menu-item menu-delimiter-top">
        <div class="icon-wrapper">
          <i class="icon-percent1"/>
        </div>
        <router-localized-link v-t="'menu.main.promos'" :to="{name: 'promos'}"/>
      </div>

      <!--   Folders   -->
      <div class="menu-item ">
        <div class="icon-wrapper">
          <i class="icon-folder"/>
        </div>
        <router-localized-link v-t="'menu.main.folders'" :to="{name: 'folders'}"/>
      </div>

      <!--   News   -->
      <div v-if="LOCALE === 'nl'" class="menu-item menu-delimiter">
        <div class="icon-wrapper">
          <i class="icon-bullhorn-2"/>
        </div>
        <router-localized-link v-t="'menu.main.news'" :to="{name: 'news'}"/>
      </div>

      <!--   Mobile app   -->
      <div class="menu-item menu-delimiter-top" >
        <div class="icon-wrapper">
          <i class="icon-mobile-app"/>
        </div>
        <router-localized-link v-t="'menu.main.mobile_app'" :to="getPagePathBySlug('mobile-app')"/>
      </div>

      <!--    Invite friend    -->
      <div class="menu-item">
        <div class="icon-wrapper">
          <i class="icon-refer-friend"/>
        </div>
        <router-localized-link :to="{name: 'referral_page'}">
          <span v-t="'menu.main.invite_friend'"/>
          <span class="ms-1">
            {{ referralReward }}
          </span>
        </router-localized-link>
      </div>

      <!--   Contact   -->
      <div class="menu-item">
        <div class="icon-wrapper">
          <i class="icon-contact"/>
        </div>
        <router-localized-link v-t="'menu.main.contact'" :to="getPagePathBySlug('contact')"/>
      </div>

      <!--   Settings   -->
      <div class="menu-item">
        <div class="icon-wrapper">
          <i class="icon-settings"/>
        </div>
        <router-localized-link v-t="'menu.user.settings'" :to="{name: 'settings'}"/>
      </div>

      <!--   Logout   -->
      <div class="menu-item">
        <div class="icon-wrapper">
          <i class="icon-logout color-primary"/>
        </div>
        <span class="color-primary" @click="logout" v-t="'menu.main.logout'"/>
      </div>

      <div v-if="xsOnly" class="menu-item">
        <locale-switcher/>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import {mapGetters, mapActions, mapState} from 'vuex';
import LocaleSwitcher from '@/components/header/LocaleSwitcher';
import RouterLocalizedLink from '@/components/common/RouterLocalizedLink';

export default {
  name: 'user-menu',
  components: {RouterLocalizedLink, LocaleSwitcher},
  mounted() {
    if (!this.BONUS_ACTIONS.length) {
      this.FETCH_BONUS_ACTIONS();
    }
  },
  computed: {
    ...mapGetters('bonus_action', [
      'BONUS_ACTIONS',
      'BONUS_ACTION_TYPES',
    ]),
    ...mapGetters('auth', [
      'USER',
      'GUEST',
      'LOCALE',
    ]),
    ...mapGetters('page', [
      'PAGES',
    ]),
    ...mapState('dialogs', {
      visible: ({dialogs}) => dialogs['user-menu'].visible,
    }),
    /**
     * referralReward
     * @returns {string}
     */
    referralReward() {
      const action = this.BONUS_ACTIONS.filter(need => need.type === this.BONUS_ACTION_TYPES['referrals-first-post-approved'])[0];
      if (!action) {
        return '';
      }
      return '(+' + action.impact_on_balance + ')';
    },
    /**
     * pagesInMenu
     * @returns {*}
     */
    pagesInMenu() {
      const thisElem = this;
      return _.filter(this.PAGES, function (page) {
        return page.in_menu && thisElem.$i18n.locale === page.locale.slug;
      })
    }
  },
  methods: {
    ...mapActions('bonus_action', [
      'FETCH_BONUS_ACTIONS',
    ]),
    ...mapActions('loader', [
      'SET_LOADER',
    ]),
    ...mapActions('auth', [
      'LOGOUT',
    ]),
    logout() {
      this.LOGOUT();
    },
  },
  watch: {
    currentScrollTop(newValue) {
      if (this.$refs.user_menu_header) {
        const userMenuClientHeight = this.$refs.user_menu_header.clientHeight + this.$refs.user_menu_body.clientHeight;
        if (userMenuClientHeight >0 && userMenuClientHeight + 95 < newValue) {
          this.$dialogs.toggle('user-menu');
        }
      }
    }
  }
}
</script>
