export const carousels = {
    mounted() {

        document.addEventListener('DOMContentLoaded', function () {
            const carousels = document.querySelectorAll('[data-scroll-container]');
            const listTypeCarousel = new URLSearchParams(window.location.search).get('listType') === 'carousel';
            carousels.forEach((carousel, i) => {
                const scrollLeftBtn = carousel.closest('.nav-buttons').querySelector('.float-start');
                const scrollRightBtn = carousel.closest('.nav-buttons').querySelector('.float-end');
                const itemWidth = carousel.querySelector('.scrollbar-item').offsetWidth;
                const listTypeCarousel = new URLSearchParams(window.location.search).get('listType') === 'carousel';

                // FOR GUEST USERS
                if (i===0 && listTypeCarousel)
                    scrollRightBtn.focus()

                let startX = 0;
                let currentX = 0;

                carousel.addEventListener('touchstart', function (event) {
                    startX = event.touches[0].clientX;
                });

                carousel.addEventListener('touchmove', function (event) {
                    currentX = event.touches[0].clientX;
                });

                carousel.addEventListener('touchend', function () {
                    if (startX - currentX > 20) {
                        scrollRight(itemWidth);
                    } else if (currentX - startX > 20) {
                        scrollLeft(itemWidth);
                    }
                });

                scrollLeftBtn.addEventListener('click', function () {
                    const w = carousel.querySelector('.scrollbar-item').offsetWidth;
                    scrollLeft(w);
                });

                // FOR GUEST USERS
                scrollLeftBtn.addEventListener('keydown', function (event) {
                    const w = carousel.querySelector('.scrollbar-item').offsetWidth;
                    switch (event.key) {
                        case 'ArrowLeft':
                            scrollLeft(w);
                            break;
                        case 'ArrowRight':
                            scrollRight(w);
                            scrollRightBtn.focus()
                            break;
                    }
                });

                scrollRightBtn.addEventListener('click', function () {
                    const w = carousel.querySelector('.scrollbar-item').offsetWidth;
                    scrollRight(w);
                });

                // FOR GUEST USERS
                scrollRightBtn.addEventListener('keydown', function (event) {
                    const w = carousel.querySelector('.scrollbar-item').offsetWidth;
                    switch (event.key) {
                        case 'ArrowLeft':
                            scrollLeft(w);
                            scrollLeftBtn.focus()
                            break;
                        case 'ArrowRight':
                            scrollRight(w);
                            break;
                    }
                });

                function scrollLeft(width) {
                    carousel.scrollBy({
                        left: -width,
                        behavior: 'smooth'
                    });
                }

                function scrollRight(width) {
                    carousel.scrollBy({
                        left: width,
                        behavior: 'smooth'
                    });
                }
            });

            // FOR GUEST USERS
            function isElementInViewport(el) {
                const rect = el.getBoundingClientRect();
                return (
                    rect.top >= 0 &&
                    rect.left >= 0 &&
                    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
                );
            }
            // FOR GUEST USERS
            function checkCarouselsInViewport() {
                carousels.forEach(carousel => {
                    if (isElementInViewport(carousel)) {
                        const scrollRightBtn = carousel.closest('.nav-buttons').querySelector('.float-end');
                        if ( listTypeCarousel)
                            scrollRightBtn.focus()
                    }
                });
            }

            // FOR GUEST USERS
            window.addEventListener('scroll', checkCarouselsInViewport);
        });
    },
};
